




































import Vue from 'vue'

import { mapGetters } from 'vuex'
import { Pagination } from 'vuelpers'
import { Accessors } from 'vue/types/options'

import { BrandQuality } from '@/consts'
import { Cart, Brand, Product } from '@/types'
import { onAddToCart } from '@/mixins/UseCart'

export default Vue.extend({
	name: 'ProductBrandItem',
	data: () => ({
		BrandQuality,
	}),
	props: {
		brand: {
			required: true,
			type: Object as Vue.PropType<Brand>,
		},
		product: {
			required: true,
			type: Object as Vue.PropType<Product>,
		},
	},
	computed: {
		...({
			...mapGetters('carts', ['$carts']),
		} as Accessors<{
			$carts: Pagination<Cart>
		}>),
		cartItem(): Cart | undefined {
			return this.$carts.data.find((cartItem) => {
				return cartItem.idBrand === this.brand.iBrandsId
			})
		},
		dSellPrice(): number {
			return +(this.brand?.dSellPrice || this.product?.dSellPrice || 0)
		},
	},
	methods: {
		onAddToCart,
		onClickAddToCart() {
			if (this.cartItem) return
			this.onAddToCart(this.product, this.brand, 1)
		},
	},
})
