














































































































import Vue from 'vue'
import { getValidators } from '@/mixins/FormMixin'
import {
	Attachment,
	BackendError,
	Id,
	ProductReview,
	User,
	VFormRef,
} from '@/types'
import { mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'
import { VDialog } from 'vuetify/lib/components'

type Review = {
	iRating: number
	vFeedback: string
	iProductReviewId: Id
	vImage: Partial<Attachment>[]
}

export default Vue.extend({
	name: 'FormProductReview',
	components: {
		VDialog,
	},
	props: {
		dialog: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		review: {
			type: Object as Vue.PropType<ProductReview>,
		},
		onSubmit: {
			required: true,
			type: Function as Vue.PropType<(review: any) => Promise<any[]>>,
		},
	},
	data: () => ({
		isValid: false,
		isLoading: false,
		attachmentError: false,
		rules: getValidators('required'),
		errors: {} as BackendError,
		mReview: {} as Review,
		ratingError: false,
		dialogModel: false,
	}),
	watch: {
		review: {
			immediate: true,
			deep: true,
			handler(v: ProductReview) {
				this.mReview = this.initialReview(v)
			},
		},
	},
	computed: {
		...(mapGetters('auth', ['$currentUser']) as Accessors<{
			$currentUser: User
		}>),
	},
	methods: {
		initialReview(v?: ProductReview): Review {
			return {
				iProductReviewId: v?.iProductReviewId ?? 0,
				iRating: v?.iRating || 0,
				vFeedback: v?.vFeedback || '',
				vImage: ((v?.vImage || '') as string)
					.split(',')
					.filter((v) => v)
					.map((v) => ({
						src: this.$helper.imgSrc(v, {
							type: 'reviews',
							size: 'none',
						}),
					})),
			}
		},
		async onSubmitReview() {
			this.ratingError = this.mReview.iRating <= 0
			if (this.ratingError) return

			this.isLoading = true
			const [err] = await this.onSubmit({
				...this.mReview,
				oldImage: this.mReview.vImage
					.filter((img) => !img.file)
					.map((img) => img.src?.split('/').pop())
					.join(','),
				vImage: this.mReview.vImage
					.filter((img) => img.file)
					.map((img) => img.file),
			})
			this.isLoading = false

			if (!err) {
				;(this.$refs.formRef as VFormRef).reset()
				this.mReview = this.initialReview()

				if (this.dialog) this.dialogModel = false
			}
		},
	},
})
