























































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import QRCode from 'qrcode'

import { groupBy, truncate } from 'lodash'
import { slugify } from '@/helpers'
import { mapActions, mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'
import { getBrand, getCartItem, onAddToCart } from '@/mixins/UseCart'

import {
	isEmpty,
	Pagination,
	encodeString,
	getVRef,
	queryString,
} from 'vuelpers'

import {
	Id,
	Cart,
	Query,
	Config,
	Product,
	Category,
	ProductView,
	SortOrder,
	Brand,
	Alias,
} from '@/types'

import {
	categorySelectWith,
	baseProductSelectWith,
	productWithSimilarAndRelated,
} from '@/queries'

import ProductsGroup from '@/components/ProductsGroup.vue'
import MiniProductsGroup from '@/components/MiniProductsGroup.vue'
import CateogoryListItem from '@/components/CategoryListItem.vue'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

import ProductBrandItem from '@/components/ProductBrandItem.vue'
import ProductReviews from '@/components/ProductReviews.vue'

import { MetaInfo } from 'vue-meta'
import { APP_META } from '@/consts'

type VFilter = {
	id: number
	tdValue: any
	thValue: any
}

export default Vue.extend({
	name: 'Product',
	metaInfo(): MetaInfo {
		const title =
			(this.$product?.vDescription || 'Product') + ` | ${APP_META.title}`
		const description = `${title} | ${this.$product?.vDescription2}`
		return {
			...APP_META,
			title,
			meta: [
				{
					name: 'description',
					content: description,
				},
				{
					name: 'keywords',
					content: description,
				},
				{
					property: 'og:title',
					content: title,
				},
				{
					property: 'og:description',
					content: description,
				},
				{
					property: 'og:url',
					content: window.location.origin,
				},
				{
					property: 'og:image',
					content: this.productThumbnails[0],
				},
			],
		}
	},
	components: {
		CoolLightBox,
		ProductsGroup,
		MiniProductsGroup,
		CateogoryListItem,
		ProductBrandItem,
		ProductReviews,
	},
	data: () => ({
		model: 0,
		isLoaded: false,
		productError: false,
		techDataTable: '',
		drawerCategory: null as boolean | null,
		coolLightBoxIndex: null as number | null,
		productQRCode: undefined as string | undefined,
		colors: ['primary', 'secondary', 'yellow darken-2', 'red', 'orange'],
		limit: {
			similar: 4,
			related: 4,
		},
	}),
	watch: {
		'$route.params.id': {
			immediate: true,
			handler(id: Id) {
				this.onFetchProduct(id)
			},
		},
	},
	created() {
		if (
			!this.$categories.data.length ||
			this.$categories.data.length !== this.$categories.total ||
			this.$categories.data.reduce(
				(carry, cat) => carry + (cat.categories?.length || 0),
				0
			) === 0
		) {
			this.onFetchCategories({
				page: 1,
				perPage: 25,
			} as Query)
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.onChangeCarousel(0)
		})
	},
	computed: {
		...({
			...mapGetters(['$config']),
			...mapGetters('category', ['$categories']),
			...mapGetters('products', [
				'$product',
				'$recentProducts',
				'$sortedRecentProductsIds',
			]),
		} as Accessors<{
			$config: Config
			$product: Product
			$sortedRecentProductsIds: any[]
			$recentProducts: Pagination<Product>
			$categories: Pagination<Category>
		}>),
		hasAliases(): boolean {
			return this.$product.aliases?.length > 0
		},
		aliasGroups(): { [key: string]: Alias[] } {
			if (!this?.$product?.aliases) return {}
			return groupBy(
				[...this.$product.aliases].sort((a, b) => {
					return a.vSkuRaw > b.vSkuRaw && a.vCo > b.vCo ? 1 : -1
				}),
				'vCo'
			)
		},
		brand(): Brand {
			return getBrand(this.$product, 'find') as Brand
		},
		reportLink(): string {
			return `/report-problem?${queryString.stringify({
				reportableId: this.$product?.iProductId,
				reportableType: 'App\\Models\\Product',
			})}`
		},
		similarProducts(): Product[] {
			if (!this.$product) return []
			return (this.$product.similarProducts || []).map((upSell) => {
				return upSell.upSellProduct
			})
		},
		relatedProducts(): Product[] {
			if (!this.$product) return []
			return (this.$product.relatedProducts || []).map((upSell) => {
				return upSell.upSellProduct
			})
		},
		productImages(): string[] {
			if (!this.$product) return []
			return (this.$product.vImage || '').split(',')
		},
		productThumbnails(): string[] {
			return this.productImages.map((src) => {
				return this.$helper.imgSrc(src, { size: 'tn' })
			})
		},
		productFullImages(): string[] {
			return this.productImages.map((src) => {
				return this.$helper.imgSrc(src, { size: 'full' })
			})
		},
		cartItem(): Cart | undefined {
			return getCartItem(this.$product) as Cart | undefined
		},
		recentProductIdsExcludingCurrentProduct(): ProductView[] {
			if (!this.$product) return this.$sortedRecentProductsIds.slice(0, 4)
			return this.$sortedRecentProductsIds
				.filter(
					(product: ProductView) => product.id !== this.$product.iProductId
				)
				.slice(0, 4)
		},
		normalPipeSize(): string {
			const nps = this.vFilters.find((filter) =>
				filter.thValue.toLowerCase().includes('size')
			)
			if (!nps) return ''
			return nps.tdValue
		},
		vFilters(): VFilter[] {
			if (!this.$product || !this.$product.category) return []

			const { category } = this.$product
			return Object.entries(this.$product.category)
				.filter(([key, value]) => {
					return key.startsWith('vFilter') && !isEmpty(value)
				})
				.map(([key, value]) => {
					const id = +key.replace('vFilter', '')
					const filters = (category.filterDatas || []).filter(
						(filter: any) => filter.iFilterId === id
					)
					const tdValue =
						filters.length && (this as any).$product[filters[0].vSqlField]
					return {
						id,
						tdValue,
						thValue: value,
					}
				})
				.filter((filter) => {
					return !isEmpty(filter.tdValue)
				})
		},
		additionalDetails(): any[] {
			if (!this.$product || !this.$product.productType) return []
			return Object.entries(this.$product.productType)
				.filter(([key, value]) => {
					return key.startsWith('vFldName') && !isEmpty(value)
				})
				.reduce((acc, [key, value]) => {
					const vFldId = +key.replace('vFldName', '')
					const pData = Object.entries(this.$product).find(
						([key, value]) => {
							const pVDataId = +key.replace('vData', '')
							return pVDataId === vFldId && value
						}
					) as any[]
					if (!pData) return acc

					const [pKey, pValue] = pData
					return acc.concat({
						id: vFldId,
						ptS: key,
						pVDS: pKey,
						thValue: value,
						tdValue: pValue,
					})
				}, [] as any[])
		},
	},
	methods: {
		slugify,
		truncate,
		onAddToCart,
		encodeString,
		...mapActions('category', ['getCategories']),
		...mapActions('products', [
			'getProductById',
			'syncRecentProducts',
			'createBrand',
		]),
		onStartEnquiry() {
			this.$router.push({
				path: '/enquiries/new',
				query: {
					iProductId: this.$product.iProductId as string,
				},
			})
		},
		async onFetchProduct(id: any) {
			this.isLoaded = false

			QRCode.toDataURL(
				location.href,
				{ width: 168, margin: 3, scale: 1 },
				(error, url) => {
					if (!error) this.productQRCode = url
				}
			)

			let [err, res] = await this.getProductById({
				id,
				query: {
					with: productWithSimilarAndRelated,
				},
			})
			this.isLoaded = true

			this.productError = !!err
			if (this.productError) return

			// console.log(getCombinations(res.product.vDescription.split(' ')))

			this.techDataTable = res.techDataTable
			this.$syncStack({
				path: this.$route.path,
				name: this.$product.vDescription,
			})

			this.syncRecentProducts(res.product)
			this.onRevealRecentProducts()

			this.limit = {
				similar: 4,
				related: 4,
			}

			this.$nextTick(() => {
				this.onChangeCarousel(0)
			})

			// this.$product.iBrands > 1 &&
			if (!this.brand) {
				// Create new brand
				const [err, newBrand] = await this.createBrand(this.$product)
				if (err) return this.$toast.error(err.message)

				// Update product brand info locally
				this.$store.commit('products/MERGE', [
					'product.brands',
					[newBrand],
					'idProduct',
				])
			}
		},
		onFetchCategories(query: Query) {
			this.getCategories({
				...query,
				orderByColumn: 'iListOrder',
				orderByValue: SortOrder.ASC,
				with: categorySelectWith,
			} as Query)
		},
		onChangeCarousel(index: number) {
			const [carouselEl] = getVRef(this.$refs.carouselRef)
			carouselEl &&
				carouselEl
					.querySelectorAll('.v-carousel__controls__item')
					.forEach((el, i) => {
						const icon = el.querySelector('.v-icon')
						if (icon) {
							if (index === i) {
								icon.classList.add('mdi-radiobox-marked')
								icon.classList.remove('mdi-radiobox-blank')
							} else {
								icon.classList.remove('mdi-radiobox-marked')
								icon.classList.add('mdi-radiobox-blank')
							}
						}
					})
		},
		onRevealRecentProducts() {
			if (this.$recentProducts.isRefetching) return
			this.$store.dispatch('products/getRecentProducts', {
				page: 1,
				perPage: 4,
				pointer: 'recent',
				with: baseProductSelectWith,
				queryArray: this.recentProductIdsExcludingCurrentProduct.map(
					(value: ProductView) => value.id
				),
			} as Query)
		},
	},
})
