




























































































import Vue from 'vue'
import { Id, ProductReview, User } from '@/types'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default Vue.extend({
	name: 'ProductReviewCard',
	components: {
		CoolLightBox,
	},
	props: {
		review: {
			required: true,
			type: Object as Vue.PropType<ProductReview>,
		},
	},
	data: () => ({
		coolLightBoxIndex: null as number | null,
	}),
	computed: {
		customerId(): Id {
			return this.$store.state.auth.currentUser?.customer?.iCustomerId
		},
		isReviewed(): boolean {
			return this.review.iCustomerId === this.customerId
		},
		images(): string[] {
			return (this.review.vImage || '')
				.split(',')
				.filter(Boolean)
				.map((src) => {
					return this.$helper.imgSrc(src, {
						type: 'reviews',
						size: 'none',
					})
				})
		},
		userThumbnail(): string {
			const vImage = this.user?.vProfileImage || ''
			return this.$helper.imgSrc(vImage.split(',')[0])
		},
		user(): User | undefined {
			return this.review?.customer?.user
		},
	},
})
