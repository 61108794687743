















































































































import Vue from 'vue'

import { ReviewApi } from '@/api/auth.api'
import { Id, Product, ProductReview } from '@/types'

import ProductReviewCard from '@/components/ProductReviewCard.vue'
import FormProductReview from '@/components/Forms/FormProductReview.vue'
import { toFormData } from 'vuelpers'

export default Vue.extend({
	name: 'ProductReviews',
	components: {
		FormProductReview,
		ProductReviewCard,
	},
	props: {
		product: {
			required: true,
			type: Object as Vue.PropType<Product>,
		},
	},
	computed: {
		customerId(): Id {
			return this.$store.state.auth.currentUser?.customer?.iCustomerId
		},
		isReviewed(): boolean {
			return this.reviews.some((review) => {
				return review.iCustomerId === this.customerId
			})
		},
		canIReview(): boolean {
			return (
				this.product.reviewableCustomers?.some((customer: any) => {
					return customer.salesOrder.iCustomerId === this.customerId
				}) || false
			)
		},
		average(): number {
			const v =
				this.reviews.reduce((acc, review) => acc + review.iRating, 0) /
				this.reviews.length
			return v || 0
		},
		reviews(): ProductReview[] {
			return this.product.productReviews ?? []
		},
		counts(): any {
			return this.reviews.reduce(
				(acc: any, review) => {
					acc[review.iRating] = acc[review.iRating] + 1
					return acc
				},
				{
					1: 0,
					2: 0,
					3: 0,
					4: 0,
					5: 0,
				}
			)
		},
	},
	methods: {
		async onSubmitReview(review: ProductReview) {
			const res = await ReviewApi.post(
				toFormData({
					...review,
					iProductId: this.product.iProductId,
				})
			)
			this.$emit('refresh-product')
			return res
		},
	},
})
