



































import Vue from 'vue'

import { Category } from '@/types'
import { slugify } from '@/helpers'
import { encodeString } from 'vuelpers'

export default Vue.extend({
	name: 'CateogoryListItem',
	props: {
		subCategory: {
			type: Boolean as Vue.PropType<boolean>,
			default: false,
		},
		category: {
			required: true,
			type: Object as Vue.PropType<Category>,
		},
	},
	methods: {
		slugify,
		encodeString,
	},
})
