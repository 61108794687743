






































































import Vue from 'vue'
import { PropType } from 'vue'
import { Product } from '@/types'
export default Vue.extend({
	name: 'MiniProductsGroup',
	props: {
		title: {
			type: String as PropType<string>,
		},
		products: {
			required: true,
			type: Array as PropType<Product[]>,
		},
		limit: {
			type: Number as PropType<number>,
			default: Infinity,
		},
	},
	data: () => ({
		mLimit: 4,
	}),
	watch: {
		limit: {
			immediate: true,
			handler(newVal: number) {
				this.mLimit = newVal
			},
		},
	},
	computed: {
		mProducts(): Product[] {
			return this.products.slice(0, this.mLimit)
		},
		isEmptyProducts(): boolean {
			return this.products.length === 0
		},
	},
	methods: {
		onScrollIntoView() {
			;(this.$refs.groupRef as Element).scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			})
		},
		onClickShowMore(next?: () => void) {
			this.mLimit += 8
			if (next) next()
		},
	},
})
